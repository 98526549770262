<template>
    <div class='menu'>
        <div v-if="sure" class="fl1 loads">
            <div class="xql"><van-loading size="26px" color="#fff" type="spinner" vertical>加载中...</van-loading></div>
        </div>
        <div class="p01" v-show="dataList">
            <!-- <van-swipe :autoplay="3000" :show-indicators="false">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <img v-lazy="image" />
                </van-swipe-item>
            </van-swipe> -->
            <img src="../../assets/index/tu.png" style="width: 100%;" @click="kf()">
            <div class="caozuo">
                <p>常用功能</p>
                <ul>
                    <li :class="dataList[item.key] == 1 ? '' : 'none'" @click="goVillage(index)"
                        v-for="(item, index) in list" :key="index">
                        <div class="flm"><img :src="item.url" class="Img"></div>
                        <div>{{ item.data }}</div>
                    </li>
                </ul>
            </div>
            <div class="caozuo" v-if="arrShow">
                <p>财务管理</p>
                <ul>
                    <li :class="dataList[item.key] == 1 ? '' : 'none'" @click="goVillages(index)"
                        v-for="(item, index) in arrList" :key="index">
                        <div class="flm"><img :src="item.url" class="Img"></div>
                        <div>{{ item.data }}</div>
                    </li>
                </ul>
            </div>
            <div class="gongju" v-if="shi">
                <p>常用工具</p>
                <ul>
                    <li :class="dataList[item.key] == 1 ? '' : 'none'" @click="goVillage1(index)"
                        v-for="(item, index) in list1" :key="index">
                        <div class="flm"><img :src="item.url" class="Img"></div>
                        <div>{{ item.data }}</div>
                    </li>
                </ul>
            </div>
            <div class="xiaoxi" v-if="shis">
                <p>消息管理</p>
                <ul>
                    <li :class="dataList[item.key] == 1 ? '' : 'none'" @click="goVillage2(index)"
                        v-for="(item, index) in list2" :key="index">
                        <div class="flm"><img :src="item.url" class="Img"></div>
                        <div>{{ item.data }}</div>
                    </li>
                </ul>
            </div>
            <div class="xiaoxi">
                <p>系统管理</p>
                <ul>
                    <li @click="goJj()" v-if="ada == 1">
                        <img src="../../assets/cd/sj.png" class="Img">
                        <div>商户进件</div>
                    </li>
                    <li @click="goHome()" v-if="old == 1">
                        <img src="../../assets/cd/old.png" class="Img">
                        <div>版本切换</div>
                    </li>
                    <li @click="goMy()">
                        <img src="../../assets/cd/my1.png" class="Img">
                        <div>个人中心</div>
                    </li>
                </ul>
            </div>
        </div>
        <div style="height: 6rem;"></div>
    </div>
</template>

<script>
import { getMenu } from '@/api/menu'
import bus from "../../store/bus"
import wx from 'weixin-js-sdk'
import { getInlet } from "../../api/index"
export default {
    components: {},
    data() {
        return {
            images: [],
            // dataList3: [],
            dataList: [],
            ada: 0,
            link: [],
            sure: false,
            shi: false,
            arrShow: false,
            shis: false,
            tab: false,
            old: '',
            a: null,
            url: null,
            worktable: '',
            list: [{
                url: require('../../assets/cd/xiaoqu2.png'),
                data: "小区管理",
                key: 'district_check',
                path: '/village'
            }, {
                url: require('../../assets/cd/dingdan2.png'),
                data: "订单管理",
                key: 'order_check',
                path: '/order'
            }, {
                url: require('../../assets/cd/sbjl2.png'),
                data: "设备管理",
                key: 'dev_check',
                path: '/device'
            }, {
                url: require('../../assets/cd/yonghu2.png'),
                data: "用户管理",
                key: 'user_check',
                path: '/user'
            }, {
                url: require('../../assets/cd/dianka2.png'),
                data: "电卡管理",
                key: 'card_check',
                path: '/elecard'
            }, {
                url: require('../../assets/cd/lxian.png'),
                data: "离线设备",
                key: 'dev_offline_check',
                path: '/leave'
            }],
            arrList: [{
                url: require('../../assets/cd/liuliang2.png'),
                data: "流量卡管理",
                key: 'iccid_check',
                path: '/traffic'
            }, {
                url: require('../../assets/cd/yunying2.png'),
                data: "数据统计",
                key: 'statistic_check',
                path: '/operate'
            }, {
                url: require('../../assets/cd/zengzhi2.png'),
                data: "增值收入",
                key: 'advert_check',
                path: '/gain'
            }, {
                url: require('../../assets/cd/tuikuan2.png'),
                data: "线上退款",
                key: 'withdraw_check',
                path: '/ref'
            }, {
                //     url: require('../../assets/cd/yuancheng2.png'),
                //     data: "远程控制",
                // },{
                //     url: require('../../assets/cd/chaobiao2.png'),
                //     data: "抄表管理",
                // },{
                url: require('../../assets/cd/tx.png'),
                data: "提现管理",
                key: 'switch_check',
                path: '/intos'
            }, {
                url: require('../../assets/cd/fc.png'),
                data: "分成管理",
                key: 'meter_check',
                path: '/into'
            }],
            list1: [{
                url: require('../../assets/cd/saoma2.png'),
                data: "扫码组网",
                key: 'join_check',
            }, {
                url: require('../../assets/cd/socx.png'),
                data: "网关信道",
                key: 'tool_ct',
                path: '/channel'
            }, {
                url: require('../../assets/cd/zduan2.png'),
                data: "终端修改",
                key: 'ct_set_check',
                path: '/ter'
            }, {
                url: require('../../assets/cd/zduan2.png'),
                data: "终端查询",
                key: 'socket_check',
                path: '/terminal'
            }, {
                url: require('../../assets/cd/shebei2.png'),
                data: "设备分配",
                key: 'assign_check',
            }, {
                url: require('../../assets/cd/wx.png'),
                data: "维护人员",
                key: 'tool_user_check',
                path: '/main'
            }],
            list2: [{
                url: require('../../assets/cd/yichang2.png'),
                data: "异常警告",
                key: 'alarm_check',
                path: '/alarm'
            }, {
                url: require('../../assets/cd/xitong2.png'),
                data: "系统信息",
                key: 'msg_check',
            }, {
                url: require('../../assets/cd/baoxiu2.png'),
                data: "保修工单",
                key: 'work_check',
            }]
        };
    },
    methods: {
        goVillage(k) {
            this.$router.push({
                path: this.list[k].path
            })
        },
        goVillages(k) {
            this.$router.push({
                path: this.arrList[k].path
            })
        },
        goVillage1(k) {
            if (!this.list1[k].path) {
                window.location.href = this.link[this.list1[k].key];
            } else {
                this.$router.push({
                    path: this.list1[k].path
                })
            }
        },
        goVillage2(k) {
            this.$router.push({
                path: this.list2[k].path
            })
        },
        goMy() {
            this.$router.push({
                path: '/my'
            })
        },
        goJj() {
            this.$router.push({
                path: '/info'
            })
        },
        phone() {
            this.$router.push({
                path: '/play'
            })
        },
        kf() {
            // var ua = navigator.userAgent.toLowerCase();
            // if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //     wx.miniProgram.getEnv((res) => {
            //         if (res.miniprogram) {
            //             wx.miniProgram.navigateTo({
            //                 url: '/pages/paykf/index?vender_id=' + localStorage.getItem("main"),
            //             });
            //             return;
            //         }
            //     })
            // }
            this.$router.push({
                path: '/ser'
            })
        },
        // 菜单权限
        async menu() {
            this.sure = true;
            let data = await getMenu({ token: localStorage.getItem('token') });
            if (data.data.code == 1) {
                this.dataList = data.data.data.check;
                this.link = data.data.data.link;
                this.url = data.data.data.old_link;
                this.old = data.data.data.old_link_check;
                for (let index = 0; index < this.list1.length; index++) {
                    if (this.dataList[this.list1[index].key] == 1) { this.shi = true; }
                }
                for (let index = 0; index < this.list2.length; index++) {
                    if (this.dataList[this.list2[index].key] == 1) { this.shis = true; }
                }
                for (let index = 0; index < this.arrList.length; index++) {
                    if (this.dataList[this.arrList[index].key] == 1) { this.arrShow = true; }
                }
            }
            this.sure = false;
            let data1 = await getInlet({ token: localStorage.getItem("token") });
            if (data1.data.code == 1) {
                this.worktable = data1.data.data.check_worktable;
                // this.alert = data1.data.data.check_alert;
                this.ada = data1.data.data.check_ada;
                bus.$emit("worktable", this.worktable);
            }
        },
        goHome() {
            window.location.href = this.url;
        }
    },
    created() {
        this.menu();
        const currentUrl = window.location.href;
        if (currentUrl.includes('/?key=')) {
            const newUrl = 'https://vender.xingkeele.com/#/menu';
            history.pushState(null, null, newUrl);
        }
    }
}
</script>
<style lang="less">
.menu {
    min-height: 100%;
    background-color: #f2f3f5;

    .p01 {
        padding: 0 1rem;
        margin-top: 1rem;
    }

    .oLi {
        display: block;
    }

    .Img {
        width: 4rem;
        height: 4rem;
    }

    .flm {
        display: flex;
        justify-content: center;
        margin-bottom: .6rem;
    }

    .caozuo {
        background-color: #fff;
        border-radius: .6rem;
        padding: 1.5rem 1.2rem 0;
        margin-top: .6rem;
    }

    .gongju,
    .xiaoxi {
        height: 10rem;
        background-color: #fff;
        border-radius: .6rem;
        padding: 1.5rem 1.2rem 1.2rem;
        margin-top: .8rem;
    }

    .gongju,
    .xiaoxi,
    .caozuo {
        p {
            margin-left: .4rem;
            font-weight: 600;
            font-size: 1.6rem;
        }

        ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 1rem 0 0;

            li {
                width: 25%;
                font-size: 1.5rem;
                margin-bottom: 1.7rem;
                text-align: center;
            }
        }
    }

    .none {
        display: none !important;
    }
}
</style>