<template>
  <div class="index1">
    <div class="p1">
      <div class="index_tt" v-if="none">
        <div class="tt_box">
          <div class="tt_top">
            <img src="../../assets/index/ld1.png">
          </div>
          <span class="tt_text" @click="more()">{{ li[0].notice_title }}</span>
          <span @click="more()">更多></span>
        </div>
      </div>
      <div class="index_head" style="margin: .6rem 0;">
        <div class="index_head_top">
          <div class="head_top_t jcsb">
            <div class="fl1">
              <div class="top_t_l">总营收 (元)</div>&nbsp;
              <img src="../../assets/index/wh.png" style="height: 1.4rem;margin-top: .3rem;"
                @click="$store.commit('xs', '不算赠送')">
            </div>
          </div>
          <div class="head_top_b">
            <div class="ib">￥</div>
            <div class="top_b_l ib fw"> {{ moneyAll }}</div>
            <div class="line" style="background-color: rgba(255, 255, 255, 0.2);"></div>
            <div class="fl1">
              <div class="head_bom mr6">
                <div class="head_bom_t jcsb">
                  <div class="bom_t_l">本月营收 (元)</div>
                </div>
                <div class="head_bom_b fl1">
                  <div class="ib">￥</div>
                  <div class="bom_b_s"><span class="fs15 fw"> {{ moneyAll1 }}</span></div>
                </div>
              </div>
              <div class="head_bom">
                <div class="head_bom_t jcsb">
                  <div class="bom_t_l">上月营收 (元)</div>
                </div>
                <div class="head_bom_b fl1">
                  <div class="ib">￥</div>
                  <div class="bom_b_s"><span class="fs15 fw"> {{ moneyAll2 }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="head_top_b" style="margin: 1rem 0;">
            <div class="fl1">
              <div class="head_bom mr6">
                <div class="head_bom_t jcsb">
                  <div class="bom_t_l">今日营收 (元)</div>
                </div>
                <div class="head_bom_b fl1">
                  <div class="ib">￥</div>
                  <div class="bom_b_s"><span class="fs15 fw"> {{ money2 }}</span></div>
                </div>
              </div>
              <div class="head_bom">
                <div class="head_bom_t jcsb">
                  <div class="bom_t_l">昨日营收 (元)</div>
                </div>
                <div class="head_bom_b fl1">
                  <div class="ib">￥</div>
                  <div class="bom_b_s"><span class="fs15 fw"> {{ money1 }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="head_top_b">
            <div class="line" style="background-color: rgba(255, 255, 255, 0.2);"></div>
            <div class="fl1">
              <div class="head_bom mr6">
                <div class="head_bom_t jcsb">
                  <div class="bom_t_l">总消费 (元)</div>
                </div>
                <div class="head_bom_b fl1">
                  <div class="ib">￥</div>
                  <div class="bom_b_s"><span class="fs15 fw"> {{ orderAll }}</span></div>
                </div>
              </div>
              <div class="head_bom">
                <div class="head_bom_t jcsb">
                  <div class="bom_t_l">本月消费 (元)</div>
                </div>
                <div class="head_bom_b fl1">
                  <div class="ib">￥</div>
                  <div class="bom_b_s"><span class="fs15 fw"> {{ orderAll1 }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fl1" style="justify-content: space-between;">
        <div class="xiaoxi fl1"
          style="width: calc(66% - 1.2rem);background: linear-gradient(180deg, #FFF3F0 0%, #FFFFFF 100%);"
          @click="sers()">
          <div>
            <div class="ind_t1 fl1">平台客服 &nbsp;
              <img src="../../assets/ss.png" style="height: 1rem;">
            </div>
            <div class="ind_t2" style="line-height: 1.8;margin-top: .4rem;">帮你接听所有用户来电</div>
            <!-- <div class="ind_t2" style="font-size: 1.2rem;">助您安心过大年</div> -->
          </div>
          <img src="../../assets/index/s11.png" alt="" style="width: 8rem;">
        </div>
        <div class="xiaoxi"
          style="width: calc(33% - 1.4rem);background: linear-gradient(180deg, #FAFFF0 0%, #FFFFFF 84.85%);"
          @click="cha()">
          <div class="ind_t1 fl1">安心充电 &nbsp;<img src="../../assets/ss.png" style="height: 1rem;"></div>
          <div class="fl1" style="justify-content: space-between;">
            <div>
              <div class="ind_t2">安心保障</div>
              <div class="ind_t2">放心充</div>
            </div>
            <img src="../../assets/index/s2.png" alt="" style="width: 3.7rem;padding-top: .6rem;">&nbsp;
          </div>
        </div>
      </div>
      <div class="fl1" style="justify-content: space-between;">
        <div class="xiaoxi"
          style="width: calc(33% - 1.4rem);background: linear-gradient(180deg, #FFFAF0 0%, #FFFFFF 84.85%)"
          @click="pla()">
          <div class="ind_t1 fl1">场地险 &nbsp;<img src="../../assets/ss.png" style="height: 1rem;"></div>
          <div class="fl1" style="justify-content: space-between;">
            <div>
              <div class="ind_t2">场地无忧</div>
              <div class="ind_t2">安全保障</div>
            </div>
            <img src="../../assets/index/s3.png" alt="" style="width: 3.7rem;padding-top: .6rem;">&nbsp;
          </div>
        </div>
        <div class="xiaoxi"
          style="width: calc(33% - 1.4rem);background: linear-gradient(180deg, #F0FFF6 0%, #FFFFFF 84.85%);"
          @click="rec()">
          <div class="ind_t1 fl1">推荐商家 &nbsp;<img src="../../assets/ss.png" style="height: 1rem;"></div>
          <div class="fl1" style="justify-content: space-between;">
            <div>
              <div class="ind_t2">推荐商家</div>
              <div class="ind_t2">获取礼金</div>
            </div>
            <img src="../../assets/index/s1.png" alt="" style="width: 3.6rem;padding-top: .6rem;">&nbsp;
          </div>
        </div>
        <div class="xiaoxi"
          style="width: calc(33% - 1.4rem);background: linear-gradient(180deg, #FFF3F0 0%, #FFFFFF 84.85%);"
          @click="wens()">
          <div class="ind_t1 fl1">想法反馈 &nbsp;<img src="../../assets/ss.png" style="height: 1rem;"></div>
          <div class="fl1" style="justify-content: space-between;">
            <div>
              <div class="ind_t2">您的建议</div>
              <div class="ind_t2">我们倾听</div>
            </div>
            <img src="../../assets/index/s5.png" alt="" style="width: 3.7rem;padding-top: .6rem;">&nbsp;
          </div>
        </div>
      </div>
      <div style="font-size: 1.5rem;margin: 1rem 0;" class="fw">详情数据展示</div>
      <!-- <div class="zhi">
        <div class="fl1 fw">
          <div class="z_nav"></div>&nbsp; 消费金额
        </div>
        <div class="fl1 z_con">
          <div style="line-height: 1.6;">
            <div class="fl1 flc fw">{{ orderAll }}</div>
            <div class="z_text">总消费 (元)</div>
          </div>
          <div class="fl1" style="line-height: 1.6;">
            <div>
              <div class="fl1 flc fw">{{ orderAll1 }}</div>
              <div class="z_text">本月消费 (元)</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="zhi">
        <div class="fl1 fw" @click="dd()">
          <div class="z_nav z_nav1"></div>
          <div class="fl1">&nbsp; 订单数量<img src="../../assets/you1.png" class="zImg"></div>
        </div>
        <div class="fl1 z_con">
          <div style="line-height: 1.6;">
            <div class="text2 fl1 flc fw">{{ orderNum }}</div>
            <div class="z_text">总订单</div>
          </div>
          <div style="line-height: 1.6;position: relative;">
            <div class="text2 fl1 flc fw">{{ orderNum1 }}</div>
            <div class="z_text">今日新增订单</div>
          </div>
          <div style="line-height: 1.6;">
            <div class="text2 fl1 flc fw">{{ orderNum2 }}</div>
            <div class="z_text">昨日新增订单</div>
          </div>
        </div>
      </div>
      <div class="zhi">
        <div class="fl1 fw" @click="yh()">
          <div class="z_nav z_nav2"></div>
          <div class="fl1">&nbsp; 用户数量<img src="../../assets/you1.png" class="zImg"></div>
        </div>
        <div class="fl1 z_con">
          <div style="line-height: 1.6;">
            <div class="text2 fl1 flc fw">{{ userNum }}</div>
            <div class="z_text">总用户</div>
          </div>
          <div style="line-height: 1.6;position: relative;">
            <div class="text2 fl1 flc fw">{{ userNum1 }}</div>
            <div class="z_text">今日新增用户</div>
          </div>
          <div style="line-height: 1.6;">
            <div class="text2 fl1 flc fw">{{ userNum2 }}</div>
            <div class="z_text">昨日新增用户</div>
          </div>
        </div>
      </div>
      <div class="index_sj">
        <div class="jcsb">
          <div class="index_sj_box" @click="xq()">
            <div class="sj_box_img"><img src="../../assets/index/1ss.png" class="box_img" /></div>
            <div class="sj_box_text">
              <div class="text1 fl1">小区数量
                <img src="../../assets/you1.png" class="zImg">
              </div>
              <div class="text2">{{ dis }}</div>
            </div>
          </div>
          <div class="index_sj_box" @click="sb()">
            <div class="sj_box_img"><img src="../../assets/index/2ss.png" class="box_img" /></div>
            <div class="sj_box_text">
              <div class="text1 fl1">端口数量
                <img src="../../assets/you1.png" class="zImg">
              </div>
              <div class="text2">{{ soc }}</div>
            </div>
          </div>
        </div>
        <div class="jcsb mg">
          <div class="index_sj_box" @click="dk()">
            <div class="sj_box_img"><img src="../../assets/index/5ss.png" class="box_img" /></div>
            <div class="sj_box_text">
              <div class="text1 fl1">电卡数量
                <img src="../../assets/you1.png" class="zImg">
              </div>
              <div class="text2">{{ car }}</div>
            </div>
          </div>
          <div class="index_sj_box" @click="zz()">
            <div class="sj_box_img"><img src="../../assets/index/6ss.png" class="box_img" /></div>
            <div class="sj_box_text">
              <div class="text1 fl1">增值积分
                <img src="../../assets/you1.png" class="zImg">
              </div>
              <div class="text2">{{ adv }}</div>
            </div>
          </div>
        </div>
        <div class="jcsb">
          <div class="index_sj_box">
            <div class="sj_box_img"><img src="../../assets/index/3ss.png" class="box_img" /></div>
            <div class="sj_box_text">
              <div class="text1">本月电量 (参考)</div>
              <div class="text2">{{ ordKey }} <span style="font-size: 1.3.3rem;">kWH</span></div>
            </div>
          </div>
          <div class="index_sj_box">
            <div class="sj_box_img"><img src="../../assets/index/4ss.png" class="box_img" /></div>
            <div class="sj_box_text">
              <div class="text1">上月电量 (参考)</div>
              <div class="text2">{{ ordKey1 }} <span style="font-size: 1.3rem;">kWH</span></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 走势图 -->
      <div class="index_tu" ref="myEcharts"></div>
      <img src="../../assets/index/tu.png" style="width: 100%;margin-top: .8rem;" @click="pla()">
    </div>
    <div style="height: 5.4rem;"></div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'
import { getSystz } from "@/api/sys"
import { getIndex } from "../../api/index"
export default {
  data() {
    return {
      baseFontSize: '',
      myChart: null,
      endDate: '',
      none: false,
      dayArr: [],
      li: [],
      listData: [{ notice_title: '兴科物联 智慧充电' }],
      staDate: null,
      moneyAll: '',
      moneyAll1: '',
      moneyAll2: '',
      orderAll: "",
      orderAll1: "",
      orderNum: '',
      orderNum1: '',
      orderNum2: '',
      userNum: '',
      userNum1: '',
      userNum2: '',
      kwhAll: "",
      dis: "",
      ordKey: '',
      money1: '',
      money2: '',
      ordKey1: '',
      soc: "",
      car: "",
      id: '',
      adv: "",
      tem: '',
      monArr: [],
      ordArr: [],
    };
  },
  methods: {
    // 获取首页数据
    getDates() {
      getIndex({ start_time: this.staDate, end_time: this.endDate }).then((data) => {
        if (data.data.code == 1) {
          let tempData = data.data.data;
          this.tem = tempData;
          this.moneyAll = this.tem.money_all;
          this.id = this.tem.vender_id;
          this.moneyAll1 = this.tem.money_month;
          this.moneyAll2 = this.tem.money_month2;
          this.money1 = this.tem.money_yesterday;
          this.money2 = this.tem.money_today;
          this.orderAll = this.tem.order_money_all;//总消费
          this.orderAll1 = this.tem.order_money_month;
          this.orderNum = this.tem.order_num_all;//总订单
          this.orderNum1 = this.tem.order_num_day;
          this.orderNum2 = this.tem.order_num_diff;
          this.userNum = this.tem.user_num_all;//总用户
          this.userNum1 = this.tem.user_num_day;
          this.userNum2 = this.tem.user_num_diff;
          if (String(Math.floor(this.tem.kwh_all)).length > 4) {
            this.kwhAll = (this.tem.kwh_all / 10000).toFixed(2);
          } else {
            this.kwhAll = this.tem.kwh_all;
          }
          if (String(Math.floor(this.tem.district_num)).length > 6) {
            this.dis = (this.tem.district_num / 10000).toFixed(2);
          } else {
            this.dis = this.tem.district_num;
          }
          if (String(Math.floor(this.tem.socket_num)).length > 6) {
            this.soc = (this.tem.socket_num / 10000).toFixed(2);
          } else {
            this.soc = this.tem.socket_num;
          }
          this.ordKey = this.tem.order_key_month;
          this.ordKey1 = this.tem.order_key_month2;
          if (String(Math.floor(this.tem.card_num)).length > 6) {
            this.car = (this.tem.card_num / 10000).toFixed(2);
          } else {
            this.car = this.tem.card_num;
          }
          if (String(Math.floor(this.tem.advert_num)).length > 6) {
            this.adv = (this.tem.advert_num / 10000).toFixed(0);
          } else {
            this.adv = this.tem.advert_num;
          }
          this.monArr = this.tem.money_arr;
          this.ordArr = this.tem.order_arr;
          this.dayArr = this.tem.day_arr;
          let optionData = {
            title: [{
              text: '近一个月走势',
              left: 10,
              top: 10,
              textStyle: { fontSize: 15, color: "#808081" }
            }],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'none'
              }
            },
            // 下载echarts图例成图片
            // toolbox: {
            //   show: true,
            //   feature: {
            //     saveAsImage: {}
            //   }
            // },
            legend: [{
              data: ["营收", "消费"],
              top: 10,
              padding: 5,
              left: 'right',
              textStyle: { color: '#808081' }
            }],
            grid: {
              top: '24%',
              left: "6%",
              right: "4%",
              bottom: "5%",
              containLabel: true,
            },
            xAxis: [{
              axisTick: { show: true },
              type: 'category',
              boundaryGap: false,
              data: this.dayArr,
              axisLabel: { interval: 7 },
            }],
            yAxis: [{
              type: "value",
              axisLabel: { show: false },
              axisTick: { show: false },
              min: 0,
              axisLine: { lineStyle: { color: '#6E7079', with: 0 } }
            }],
            series: [{
              name: "营收",
              data: this.monArr,
              type: 'line',
              smooth: true,
              symbol: 'none'
            }, {
              name: "消费",
              smooth: true,
              symbol: 'none',
              data: this.ordArr,
              type: "line",
            }],
            color: ['#8DCF00', '#1260FC']
          };
          this.myChart.setOption(optionData);
          window.onresize = this.myChart.resize;
          this.golist();
        }
      })
    },
    goVillage2(k) {
      this.$router.push({
        path: this.list2[k].path,
        query: { id: this.id }
      })
    },
    rec() {
      this.$router.push({
        path: '/recom',
        query: { id: this.id }
      })
    },
    cha() {
      this.$router.push({
        path: '/charg',
        query: { id: this.id }
      })
    },
    pla() {
      this.$router.push({
        path: '/play',
        query: { id: this.id }
      })
    },
    sers() {
      this.$router.push({
        path: '/ser',
        query: { id: this.id }
      })
      // var ua = navigator.userAgent.toLowerCase();
      // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //   wx.miniProgram.getEnv((res) => {
      //     if (res.miniprogram) {
      //       wx.miniProgram.navigateTo({
      //         url: '/pages/paykf/index?vender_id=' + localStorage.getItem("main"),
      //       });
      //       return;
      //     }
      //   })
      // }
    },
    wens() {
      this.$router.push({
        path: '/wen',
        query: { id: this.id }
      })
    },
    // 更多
    more() {
      this.$router.push({
        path: '/more'
      })
    },
    xq() {
      this.$router.push({
        path: '/village'
      })
    },
    sb() {
      this.$router.push({
        path: '/device'
      })
    },
    dd() {
      this.$router.push({
        path: '/order'
      })
    },
    yh() {
      this.$router.push({
        path: '/user'
      })
    },
    dk() {
      this.$router.push({
        path: '/elecard'
      })
    },
    zz() {
      this.$router.push({
        path: '/gain'
      })
    },
    yy() {
      this.$router.push({
        path: '/operate'
      })
    },
    async golist() {
      let data1 = await getSystz();
      if (data1.data.data.length > 0) {
        this.listData = data1.data.data;
        let a = data1.data.data.some(item => { return item.is_read == '0'; });
        if (a) {
          this.none = true;
        } else {
          this.none = false;
        }
        this.li = data1.data.data.filter(item => item.is_read == '0');
      }
    }
  },
  created() {
    if (Object.keys(this.$route.query).length != 0) {
      this.tem = this.$route.query.tem;
      this.listData = this.$route.query.listData;
      this.moneyAll = this.tem.money_all;
      this.money1 = this.tem.money_yesterday;
      this.money2 = this.tem.money_today;
      this.moneyAll1 = this.tem.money_month;
      this.moneyAll2 = this.tem.money_month2;
      this.orderAll = this.tem.order_money_all;//总消费
      this.orderAll1 = this.tem.order_money_month;
      this.orderNum = this.tem.order_num_all;//总订单
      this.orderNum1 = this.tem.order_num_day;
      this.orderNum2 = this.tem.order_num_diff;
      this.userNum = this.tem.user_num_all;//总用户
      this.userNum1 = this.tem.user_num_day;
      this.userNum2 = this.tem.user_num_diff;
      if (String(Math.floor(this.tem.kwh_all)).length > 4) {
        this.kwhAll = (this.tem.kwh_all / 10000).toFixed(2);
      } else {
        this.kwhAll = this.tem.kwh_all;
      }
      if (String(Math.floor(this.tem.district_num)).length > 6) {
        this.dis = (this.tem.district_num / 10000).toFixed(2);
      } else {
        this.dis = this.tem.district_num;
      }
      if (String(Math.floor(this.tem.socket_num)).length > 6) {
        this.soc = (this.tem.socket_num / 10000).toFixed(2);
      } else {
        this.soc = this.tem.socket_num;
      }
      this.ordKey = this.tem.order_key_month;
      this.ordKey1 = this.tem.order_key_month2;
      if (String(Math.floor(this.tem.card_num)).length > 6) {
        this.car = (this.tem.card_num / 10000).toFixed(2);
      } else {
        this.car = this.tem.card_num;
      }
      if (String(Math.floor(this.tem.advert_num)).length > 6) {
        this.adv = (this.tem.advert_num / 10000).toFixed(0);
      } else {
        this.adv = this.tem.advert_num;
      }
      this.monArr = this.tem.money_arr;
      this.ordArr = this.tem.order_arr;
      this.dayArr = this.tem.day_arr;
    }
    this.staDate = this.$moment().startOf('month').format('YYYY-MM-DD');
    // 向前推30天
    // this.staDate = this.$moment().subtract(30, 'days').format('YYYY-MM-DD');
    // 当天日期
    this.endDate = this.$moment().subtract(0, 'months').format('YYYY-MM-DD');
    // this.myChart = this.$echarts.init(this.$refs.myEcharts);
  },
  mounted() {
    this.baseFontSize = document.documentElement.style.fontSize;
    this.myChart = this.$echarts.init(this.$refs.myEcharts);
    this.getDates();
  }
}
</script>
<style lang="less">
.zImg {
  margin-left: .4rem;
  height: 1rem;
}

.zhi {
  width: calc(100% - 2rem);
  padding: 1rem;
  margin-bottom: .8rem;
  background-color: #fff;
  border-radius: .4rem;
  color: #4a4a4a;

  .ind_zr {
    position: absolute;
    top: 0;
    right: -1rem;
    font-size: 1.3rem;
  }

  .z_nav {
    width: .2rem;
    height: 1.4rem;
    border-radius: .6rem;
    background-color: #FFA64D;
  }

  .ts {
    align-items: first baseline !important;
    line-height: 1.6;
  }

  .ys {
    font-size: 1.3rem;
  }

  .z_nav1 {
    background-color: #1260FC;
  }

  .z_nav2 {
    background-color: #8DCF00;
  }

  .z_con {
    margin-top: 1.2rem;
    justify-content: space-around;

    .z_text {
      text-align: center;
      font-size: 1.4rem;
      color: #999;
    }
  }
}

.xiaoxi {
  height: 6.4rem;
  background-color: #fff;
  border-radius: .6rem;
  justify-content: space-between;
  padding: 1rem .6rem;
  margin-top: .5rem;

  .ind_t1 {
    font-size: 1.5rem;
    padding: 0 .8rem;
  }

  .ind_t2 {
    font-size: 1.3rem;
    padding: 0 0 0 .8rem;
    color: #999;
  }

  .ind_t3 {
    font-size: 1.3rem;
    color: #FB6C6A;
    padding: .2rem .8rem;
    border: .1rem solid #FB6C6A;
    border-radius: 3rem;
  }

  .Img {
    width: 4rem;
    height: 4rem;
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 20%;
      height: 6rem;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}


.fw {
  font-weight: 700;
}
</style>
<!-- <style lang="less">
.index1 {
  height: 100vh;
  background-color: #f2f3f5;

  .h1 {
    height: 1rem;
  }

  .p1 {
    padding: 0 1rem;
  }

  .fsc {
    font-size: 1.2rem;
    color: #e9e9e9;
  }

  .tt_top {
    width: 2.4rem;
    height: 4rem;

    img {
      width: 100%;
      height: 2.4rem;
      margin-top: 1rem;
    }
  }

  .fsc1 {
    font-size: 1.3rem;
    color: #949494;
  }

  .mt_5 {
    margin-top: 0.5rem;
  }

  .fl {
    display: flex;
    align-items: flex-end;
  }

  .fs15 {
    font-size: 1.5rem;
  }

  .seamless {
    height: 2.6rem;
    overflow: hidden;
    
  }

  .index_head_top {
    height: 7rem;
    background: url(../../assets/index/bgs.png) 0 0 no-repeat;
    background-size: 100% 100%;
    padding: 1.5rem 1.5rem 1rem;

    .head_top_t {
      height: 1.8rem;

      .top_t_l {
        font-size: 1.6rem;
        color: #ffffffcc;
      }

      .top_t_r {
        
        color: #ffffff;
      }
    }

    .head_top_b {
      color: #ffffff;

      .top_b_l {
        margin-top: .8rem;
        font-size: 2.2rem;
      }

      .top_b_r {
        
        text-align: right;

        .top_b_r_text {
          width: 7rem;
        }
      }
    }
  }

  .index_head_bom {
    color: #ffffff;
    margin-top: .6rem;

    .head_bom {
      padding: 1rem;
      width: 45%;
      height: 7rem;

      .head_bom_t {
        .bom_t_l {
          font-size: 1.6rem;
          color: #ffffffcc;
        }

        .bom_t_r {
          img {
            width: 2.4rem;
          }
        }
      }

      .head_bom_b {
        
        margin-top: .2rem;
        color: #ffffffcc;
      }
    }

    .blue {
      background: url(../../assets/index/bg1.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }

    .green {
      background: url(../../assets/index/bg2.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }

  .index_tt {
    background-color: #fff;
    border-radius: .4rem;
    margin: .6rem 0;
    height: 4rem;
    color: #a5a7b1;

    .tt_box {
      display: flex;
      align-items: center;
      margin: 0 1rem 0 .5rem;
    }

    .tt_text {
      margin-left: .4rem;
      overflow: hidden;
      width: 80%;
    }
  }

  .index_sj {
    height: 16.5rem;
    background: #fff;
    box-shadow: 0rem .1rem .4rem .1rem rgba(0, 0, 0, 0.05);
    border-radius: .6rem;
    padding: 1.5rem;
    margin: 1rem 0;

    .index_sj_box {
      width: 20rem;
      display: flex;

      .box_img {
        width: 2.8rem;
        height: 2.8rem;
      }

      .sj_box_img {
        margin: 0 1.8rem 0 .8rem;
      }

      .text1 {
        font-size: 1.5rem;
        margin-bottom: .4rem;
        color: #a5a7b1;
      }

      .text2 {
        font-size: 1.6rem;
        color: #4a4a4a;
      }
    }
  }

  .index_tu {
    height: 20rem;
    background: #fff;
    box-shadow: 0rem .1rem .2rem 0rem rgba(0, 0, 0, 0.06),
      0rem .1rem .2rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: .6rem;
    
  }

  .ib {
    display: inline-block;
  }

  .jcsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fs20 {
    font-size: 2rem;
  }

  .mr6 {
    margin-right: .6rem;
  }

  .fs18 {
    font-size: 1.8rem;
  }

  .mg {
    margin: 1.3rem 0;
  }

  canvas {
    width: 98% !important;
  }
}
</style> -->